<template>
  <div class="registry-sign-up-form mt-0">
    <div class="profile-wrapper" v-if="companyProfile">
      <div class="profile-content">
        <div class="user-profile-image">
          <div class="profile-image">
            <div id="preview" class="profile-image cstm-cursor">
              <!-- <img v-if="url" :src="url" />
              <img v-else src="../../assets/images/company-logo.png" alt="" /> -->

              
            <div class="img-loader" v-if="isImageLoaded">             
                <div class="lds-dual-ring-btn" id="loading"></div>
             </div>
            <img v-if="url" :src="url" @load="onImageLoad" /> 
            <img v-else src="../../assets/images/company-logo.png" alt="" @load="onImageLoad" /> 
            </div>
          </div>

          <div class="change-profile-image justify-content-center">
            <form>
              <input
                type="file"
                id="vue-file-upload-input-company-profile-photo"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
              />

              <!-- <div class="change-icon">
                <span class="text">Company Logo</span>
              </div> -->
            </form>
          </div>
          <div class="btns text-center">
            <button class="custom-btn2 px-4" @click="editCompanyProfile()">
              Edit Company Details
            </button>
          </div>
        </div>
        <div class="user-profile-data">
          <h6 class="title">Company Information</h6>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Company Name</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_name"
                    v-model="details.company_name"
                    placeholder="Enter Company Name"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6 p-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Company Website </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_website"
                    v-model="details.company_website"
                    placeholder="Enter Company Website"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>

              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">LinkedIn </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_linkedIn"
                    v-model="details.linkedIn"
                    placeholder="Enter LinkedIn Account"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Twitter </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_twitter"
                    v-model="details.twitter"
                    placeholder="Enter Twitter Account"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>

              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Company Size </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_size"
                    v-model="details.company_size"
                    placeholder="Company Size"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Industry </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_Industry"
                    v-model="details.Industry"
                    placeholder="Enter Industry"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Sector </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_Sector"
                    v-model="details.Sector"
                    placeholder="Enter Sector"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Company Address 1 </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    v-model="details.address"
                    placeholder="Enter Address"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>

              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Company Address 2 </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address2"
                    v-model="details.address_two"
                    placeholder="Enter Address"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">City</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    v-model="details.city"
                    placeholder="Enter City"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">State</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="state"
                    v-model="details.state"
                    placeholder="Enter State"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-4 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Zip Code</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="zip_code"
                    v-model="details.zip_code"
                    placeholder="Enter Zip Code"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
           
            </div>
          </div>
          <h6 class="title">Legal Verifier Information</h6>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Legal Verifier Contact Name </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="legal_name"
                    v-model="details.legal_name"
                    placeholder="Enter Name"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <!-- Legal Verifier Contact Title -->
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Legal Verifier Contact Title</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="legal_title"
                    v-model="details.legal_title"
                    placeholder="Enter Title"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
              <!-- Legal Verifier Contact Email -->
              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Legal Verifier Contact Email </span>
                    </div>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="legal_email"
                    v-model="details.legal_email"
                    placeholder="Enter Email"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-wrapper" v-if="updateCompanyProfile">
      <div class="profile-content">
        <!-- <div
          id="overlay"
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
          v-if="message != null"
        >
          <p class="error">
            {{ message }}
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->

        <div class="user-profile-image">
          <div class="profile-image">
            <div id="preview" class="profile-image cstm-cursor" >
              <img src="../../assets/images/penWhite.png" class="profile-icon-dark d-none" alt="" @click="openinput()">
              <img src="../../assets/images/penGrey.png" class="profile-icon" alt="" @click="openinput()">

              <!--<img v-if="url" :src="url" />
              <img v-else src="../../assets/images/company-logo.png" alt="" /> -->

              <div v-if="isImageLoaded" class="img-loader">             
                <div  class="lds-dual-ring-btn" id="loading"></div>
              </div>
              <img v-if="url" :src="url" @load="onImageLoad" /> 
              <img v-else src="../../assets/images/company-logo.png" alt="" @load="onImageLoad" />
                    
            </div>
          </div>

          <div
            class="change-profile-image justify-content-center"
            @click="openinput()"
          >
            <form>
              <input
                type="file"
                id="vue-file-upload-input-company-profile-photo"
                @change="onFileChange"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
              />

              <!-- <div class="change-icon">
                <span class="text">Change Company Logo</span>
              </div> -->
            </form>
          </div>
          <div class="profile-btns">
            <button
              class="custom-btn2"
              @click="Update(0)"
              :disabled="isLoadingArray"
            >
              <div v-if="isLoadingArray" class="lds-dual-ring"></div>
              <span v-else>Save Changes</span>
            </button>
            <button class="custom-btn2" @click="cancelUpdate()">Cancel</button>
          </div>
        </div>
        <div class="user-profile-data">
          <h6 class="title">Company Information</h6>
          <div class="container-fluid">
            <div class="row">
              <!-- Company Name -->
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Company Name <span class="astrick">*</span></span
                      >
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_name"
                    placeholder="Enter Company Name"
                    autocomplete="details.company_name"
                    v-model.trim="$v.details.company_name.$model"
                    :class="{
                      'is-invalid': $v.details.company_name.$error,
                      'is-valid': !$v.details.company_name.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.company_name.required"
                      >Company name is required</span
                    >
                    <span v-if="!$v.details.company_name.minLength"
                      >Company name must have at least
                      {{ $v.details.company_name.$params.minLength.min }}</span
                    >
                    <span v-if="!$v.details.company_name.maxLength"
                      >Company name must have at most
                      {{ $v.details.comapny_name.$params.maxLength.max }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- Company Website -->
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Company Website <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_website"
                    placeholder="Enter Company Website"
                    autocomplete="details.company_website"
                    v-model.trim="$v.details.company_website.$model"
                    :class="{
                      'is-invalid': $v.details.company_website.$error,
                      'is-valid': !$v.details.company_website.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.company_website.required"
                      >Company Website is required</span
                    >
                    <span v-if="!$v.details.company_website.hostname"
                      >Enter Valid Url</span
                    >
                  </div>
                </div>
              </div>
              <!-- LinkedInn -->
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">LinkedIn </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_linkedIn"
                    placeholder="Enter LinkedIn Account"
                    autocomplete="details.linkedIn"
                    v-model.trim="details.linkedIn"
                  />
                </div>
              </div>
              <!-- Twitter -->
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Twitter </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="company_twitter"
                    placeholder="Enter Twitter Account"
                    autocomplete="details.twitter"
                    v-model.trim="details.twitter"
                  />
                </div>
              </div>
              <!-- Company Size -->
              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Company Size <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <v-select
                    v-model="$v.details.company_size.$model"
                    :options="sizeOptions"
                    label="size"
                    @input="setSize"
                    :placeholder="'Select Company Size'"
                    class="custom-select pl-0"
                    :class="{
                      'is-invalid': $v.details.company_size.$error,
                      'is-valid': !$v.details.company_size.$invalid,
                    }"
                  />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.company_size.required">
                    Company size is required
                  </span>
                </div>
                </div>
              </div>

              <!-- Industry -->
              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Industry <span class="astrick">*</span></span
                      >
                    </div>
                  </label>
                  <v-select
                    v-model="$v.details.Industry.$model"
                    :options="Object.keys(list)"
                    placeholder="Select Industry"
                    @input="clearSector"
                    :clearable="true"
                    class="custom-select pl-0"
                    :class="{
                      'is-invalid': $v.details.Industry.$error,
                      'is-valid': !$v.details.Industry.$invalid,
                    }"
                    ></v-select>

                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.Industry.required">Industry is required</span>
                    </div>

                </div>
              </div>
              <!-- sector -->
              <div class="col-lg-4 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Sector <span class="astrick">*</span></span
                      >
                    </div>
                  </label>
                  <v-select
                  v-model="$v.details.Sector.$model"
                  :options="list[details.Industry]"
                  placeholder="Select Sector"
                  @input="setSector"
                  class="custom-select pl-0"
                  :class="{
                    'is-invalid': $v.details.Sector.$error,
                    'is-valid': !$v.details.Sector.$invalid,
                  }"
                  label="text"
                  :clearable="true"
                >
                <template #no-options>
                    <span>Please select industry to select sector.</span>
                </template>
                </v-select>
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.Sector.required">Sector is required</span>
                </div>

                </div>
              </div>

               <!-- Address 1 -->
               <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Company Address 1 <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="Enter Address"
                    autocomplete="details.address"
                    v-model.trim="$v.details.address.$model"
                    :class="{
                      'is-invalid': $v.details.address.$error,
                      'is-valid': !$v.details.address.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.address.required"
                      >Address is required</span
                    >
                  </div>
                </div>
              </div>
              <!-- Address 2 -->
              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Company Address 2 </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address_two"
                    placeholder="Enter Address"
                    autocomplete="details.address_two"
                    v-model.trim="details.address_two"
                  />
                </div>
              </div>
              <!-- city -->
              <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >City <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    placeholder="Enter City"
                    autocomplete="details.city"
                    v-model.trim="$v.details.city.$model"
                    :class="{
                      'is-invalid': $v.details.city.$error,
                      'is-valid': !$v.details.city.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.city.required"
                      >City is required</span
                    >
                  </div>
                </div>
              </div>
                <!-- state -->
                <div class="col-lg-4 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >State <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <v-select
                    v-model.trim="$v.details.state.$model"
                    :options="stateList"
                    label="name"
                    :placeholder="statePlaceholder"
                    @input="setSelected"
                    class="custom-select p-0"
                    :class="{
                      'is-invalid': $v.details.state.$error,
                      'is-valid': !$v.details.state.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.state.required"
                      >State is required</span
                    >
                  </div>
                </div>
              </div>
              <!-- Zip code -->
              <div class="col-lg-4 px-0 ">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Zip Code <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="zip_code"
                    placeholder="Enter Zip Code"
                    autocomplete="details.zip_code"
                    v-model.trim="$v.details.zip_code.$model"
                    :class="{
                      'is-invalid': $v.details.zip_code.$error,
                      'is-valid': !$v.details.zip_code.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.zip_code.required"
                      >Zip Code is required</span
                    >
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          <h6 class="title">Legal Verifier Information</h6>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Legal Verifier Contact Name <span class="astrick">*</span> </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="legal_name"
                    placeholder="Enter Name"
                    autocomplete="details.legal_name"
                    v-model.trim="$v.details.legal_name.$model"
                    :class="{
                      'is-invalid': $v.details.legal_name.$error,
                      'is-valid': !$v.details.legal_name.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.legal_name.required"
                      >Verifier Name is required</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Legal Verifier Contact Title <span class="astrick">*</span></span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="legal_title"
                    placeholder="Enter Title"
                    autocomplete="details.legal_title"
                    v-model.trim="$v.details.legal_title.$model"
                    :class="{
                      'is-invalid': $v.details.legal_title.$error,
                      'is-valid': !$v.details.legal_title.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.legal_title.required"
                      >Title is required</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Legal Verifier Contact Email <span class="astrick">*</span></span>
                    </div>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="legal_email"
                    placeholder="Enter Email"
                    autocomplete="details.legal_email"
                    v-model.trim="$v.details.legal_email.$model"
                    :class="{
                      'is-invalid': $v.details.legal_email.$error,
                      'is-valid': !$v.details.legal_email.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.legal_email.required"
                      >Email is required</span
                    >
                    <span v-if="!$v.details.legal_email.email"
                      >Must be Valid Email</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
let states = require("../../assets/js/states");
import {
  required,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import { helpers } from 'vuelidate/lib/validators' 
const hostname = helpers.regex('hostname', /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+[^\s/$.?#].[^\s])*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)

export default {
  name: "CompanyProfile",
  data() {
    return {
      isImageLoaded: true,
      companyProfile: false,
      updateCompanyProfile: true,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      url: null,
      isLoadingArray: false,
      companyLogo: null,
      oldPicture: true,
      verify_email_check: 0,
      errorLegal: false,
      message: "",
      details: {
        company_name: "",
        company_website: "",
        linkedIn: "",
        twitter: "",
        address: "",
        address_two: "",
        city: "",
        state: "",
        zip_code: "",
        legal_name: "",
        legal_title: "",
        legal_email: "",
        Sector: "",
        Industry: "",
        company_size: "",
      },
      stateList: states,
      statePlaceholder: "Select State",
      list: {
        Agriculture: [
          { id: "1", text: "Farming" },
          { id: "2", text: "Dairy" },
        ],
        "Arts, Entertainment & Recreation": [
          { text: "Agents and Managers", id: "3" },
          { text: "Independent Artist, Writers and Performers", id: "4" },
          { text: "Performing Arts", id: "5" },
          { text: "Promoters", id: "6" },
          { text: "Spectator Sports", id: "7" },
        ],
        "Facilities Services": [
          { text: "Call Center", id: "8" },
          { text: "Janitorial", id: "9" },
          { text: "Building Security", id: "10" },
        ],
        Construction: [
          { text: "Carpenter", id: "11" },
          { text: "Drywall", id: "12" },
          { text: "Electrical", id: "13" },
          { text: "Finishwork", id: "14" },
          { text: "Floor", id: "15" },
          { text: "Framing", id: "16" },
          { text: "General Contractor", id: "17" },
          { text: "Landscaping", id: "18" },
          { text: "Masonry", id: "19" },
          { text: "Painting", id: "20" },
          { text: "Plumbing", id: "21" },
        ],
        "Education & Childcare": [
          { text: "After School", id: "22" },
          { text: "Camp", id: "23" },
          { text: "Commercial Day Care", id: "24" },
          { text: "Home Day Care", id: "25" },
          { text: "STEM", id: "26" },
          { text: "Tutoring", id: "27" },
        ],
        Energy: [
          { text: "Alternative", id: "28" },
          { text: "Natural", id: "29" },
        ],
        Finance: [
          { text: "Banking", id: "30" },
          { text: "Financial Investment", id: "31" },
          { text: "Private Equity", id: "32" },
          { text: "Venture Capital", id: "33" },
          { text: "Angel Investing", id: "34" },
        ],
        Healthcare: [
          { text: "Physician", id: "35" },
          { text: "Ambulatory", id: "36" },
          { text: "Clinic", id: "37" },
          { text: "Hospice", id: "38" },
          { text: "Hospital", id: "39" },
          { text: "Nursing Home", id: "40" },
          { text: "Social Services", id: "41" },
          { text: "At Home Care", id: "42" },
        ],
        Hospitality: [
          { text: "Bars and Restaurants", id: "43" },
          { text: "Hotels and Motels", id: "44" },
          { text: "Venue Rental", id: "45" },
        ],
        Manufacturing: [
          { text: "Food & Beverage", id: "46" },
          { text: "Tobacco", id: "47" },
          { text: "Chemicals", id: "48" },
          { text: "Goods", id: "49" },
          { text: "Building Materials", id: "50" },
          { text: "Industrial Machinery", id: "51" },
          { text: "Textile", id: "52" },
          { text: "Apparel", id: "53" },
          { text: "Electrical Equipment", id: "54" },
          { text: "Furniture", id: "55" },
          { text: "Electronics", id: "56" },
          { text: "Plastics and Rubber", id: "57" },
          { text: "Paper", id: "58" },
          { text: "Pharmaceuticals", id: "59" },
        ],
          "Non-Profit":[
          { text: "Charitable Work", id: "61" },
          { text: "Foundation", id: "62" },
          { text: "Religious Organization", id: "63" },
          { text: "Youth Program", id: "64" },
          ],

        "Professional Services": [
          { text: "Accounting", id: "65" },
          { text: "Marketing & Advertising", id: "66" },
          { text: "Architecture", id: "67" },
          { text: "Dentist", id: "68" },
          { text: "Engineering", id: "69" },
          { text: "IT Services", id: "70" },
          { text: "Law Firm", id: "71" },
          { text: "Legal & Law Firm Services", id: "72" },
          { text: "Management Consulting", id: "73" },
          { text: "Design", id: "74" },
          { text: "Scientific Research", id: "75" },
          { text: "Staffing and Recruiting", id: "77" },
          
        ],
        "Real Estate": [
          { text: "Agent", id: "78" },
          { text: "Appraisal and Inspection", id: "79" },
          { text: "Broker", id: "80" },
          { text: "Landlord", id: "81" },
          { text: "Property Management", id: "82" },
          { text: "Tenant", id: "83" },
        ],
        Retail: [
          { text: "Beauty Salon", id: "84" },
          { text: "Building Material", id: "85" },
          { text: "Cleaning", id: "86" },
          { text: "Clothing and Accessories", id: "87" },
          { text: "Dry Cleaning", id: "88" },
          { text: "Electronics", id: "89" },
          { text: "Food and Beverage", id: "90" },
          { text: "Gardening", id: "91" },
          { text: "Gasoline Station", id: "92" },
          { text: "General Merchandise Store", id: "93" },
          { text: "Gym", id: "94" },
          { text: "Home Furnishings", id: "95" },
          { text: "Laundromat", id: "96" },
          { text: "Motor Vehicle Dealer", id: "97" },
          { text: "Motor Vehicle Parts", id: "98" },
          { text: "Personal Trainer", id: "99" },
          { text: "Spa", id: "100" },
          { text: "Sporting Goods", id: "101" },
          { text: "Transportation", id: "102" },
          { text: "Wholesale Distribution", id: "103" },
          { text: "Other", id: "104" },
        ],
        Technology: [
        { text: "Software", id: "105" },
        { text: "Hardware", id: "106" },
        { text: "Cloud Service", id: "107" },
        { text: "Internet", id: "108" },
        { text: "eCommerce", id: "109" },
        { text: "Telecom", id: "110" },
        { text: "Robotics", id: "111" },
        { text: "AI", id: "112" },
        { text: "Data Platforms", id: "113" },
        { text: "Data Providers", id: "114" },
        ],
        "Business Service": [
          {text: "Staffing and Recruiting", id:"115" },
          {text: "Amored Security", id:"116" },
          {text: "Catering", id:"117" },
        ],
      },
      sizeOptions: [
        { size: "1 > 10" },
        { size: "10 > 50" },
        { size: "50 > 100" },
        { size: "100 > 500" },
        { size: "500 > 1000" },
        { size: "1000 > above" },
      ],
    };
  },
  validations: {
    details: {
      company_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      company_website: {
        required,
        hostname
      },
      address: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zip_code: {
        required,
      },
      legal_name: {
        required,
      },
      legal_title: {
        required,
      },
      legal_email: {
        required,
        email,
      },
      Sector: {
        required,
      },
      Industry: {
        required,
      },
      company_size: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "userDomain"]),
  },
created() {
  this.isLastNameEmpty();
},
  methods: {
    ...mapActions("auth", ["getUserData"]),
    onImageLoad() {
      this.isImageLoaded = false; 
    },
    clearSector(){
      this.details.Sector= null;
    },
    editCompanyProfile: function() {
      this.updateCompanyProfile = true;
      this.companyProfile = false;
    },
    cancelUpdate: function() {
      this.isLoadingArray= false;
      this.companyProfile = true;
      this.updateCompanyProfile = false;
      this.dataGet();
    },
    setSize(value) {
      if(value){
        this.details.company_size = value.size;
      }
      
    },
    setSelected(value) {
      if(value){
      this.details.state = value.name;
      }
    },
    setSector(value) {
      if(value){
      this.details.Sector = value.text;
      } 
      
    },
    openinput: function() {
      document
        .getElementById("vue-file-upload-input-company-profile-photo")
        .click();
    },
    onFileChange(e) {
      this.oldPicture = false;
      this.companyLogo = null; // Clear the previous image
      this.companyLogo = e.target.files[0]; // Assign new image
      this.url = URL.createObjectURL(this.companyLogo);
    },
    Update: function(index) {
      this.$v.$touch();
      if(this.details.Industry && !this.details.Sector){
        this.$swal(
          "",
          "Please select sector after selecting industry.",
          "error"
        );
      }
      if (this.$v.$pendding || this.$v.$error) return;
      var formData = new FormData();
      if (this.user.companyLegal != null) {
        if (
          this.details.legal_email !==
          this.user.companyLegal.legal_contact_email
        ) {
          this.verify_email_check = 1;
        }
      } else {
        this.verify_email_check = 1;
      }
      const email = this.details.legal_email;
      const website = this.details.company_website;
      const emailDomain = email.split("@").pop();
      const websiteDomain = website.replace(/(^\w+:|^)\/\//, "").split("/")[0].split('.').slice(-2).join('.');
      // if (address !== this.userDomain) {
      //   this.errorLegal = true;
      //   return;
      // } else {
      //   this.errorLegal = false;
      // }
      if (emailDomain !== this.userDomain || websiteDomain !== this.userDomain) {
        this.$swal(
          "Oops...",
          "Please check your website and legal verifier email. Must match your email domain.",
          "error"
        );
        return;
      }
      //this.$set(this.isLoadingArray, index, true);
       this.isLoadingArray=true;
      formData.append("company_name", this.details.company_name);
      formData.append("company_website", this.details.company_website);
      formData.append("address_1", this.details.address);
      formData.append("city", this.details.city);
      formData.append("address_2", this.details.address_two);
      formData.append("state", this.details.state);
      formData.append("zip_code", this.details.zip_code);
      formData.append("industry", this.details.Industry);
      formData.append("sector", this.details.Sector);
      // Only append the avatar if a new one is uploaded
      if (this.companyLogo) {
        formData.append("company_logo", this.companyLogo);
      }
      formData.append("legal_contact_name", this.details.legal_name);
      formData.append("legal_contact_title", this.details.legal_title);
      formData.append("legal_contact_email", this.details.legal_email);
      formData.append("twitter_profile_url", this.details.twitter);
      formData.append("linkedin_profile_url", this.details.linkedIn);
      formData.append("company_size", this.details.company_size);
      formData.append("verify_email_check", this.verify_email_check);
      axios
        .post(process.env.VUE_APP_API_URL + "registry/update", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.getUserData()
            this.$swal({
              title: "Your company details have been updated!",
              icon: "success",
              showCancelButton: false,
            }).then((result) => {
              if (result.isConfirmed) {
                //this.$set(this.isLoadingArray, index, false);
                this.isLoadingArray=false;
                // this.getUserData();
                this.companyProfile = true;
                this.updateCompanyProfile = false;
                this.verify_email_check = 0;
                if (this.user.user.secret_answer == null) {
                  this.$swal({
                    title: "Please complete user details!",
                    icon: "info",
                    showCancelButton: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.$router.push({ name: "Profile" });
                    }
                  });
                }else{
                  if(!this.user.company || this.user.company.nda_status !== 1){
                    this.$router.push({ name: "GlobalNDAForm" });
                  }
                }
              }
            });
          } else {
            this.$set(this.isLoadingArray, index, false);
            this.message = response.data.message;
            this.$swal({
              text: this.message,
              icon: "error",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.verify_email_check = 0;
            alert(error.response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    dataGet() {
      this.$v.$reset();
      this.details.company_name = "";
          this.details.company_website = "";
          this.url = "";;
          this.details.zip_code = "";
          this.details.address = "";
          this.details.city = "";
          this.details.Sector = "";
          this.details.company_size = "";
          this.details.Industry = "";
          this.details.address_two = "";
          this.details.state = "";
          this.details.legal_name = "";
          this.details.legal_title = "";
          this.details.legal_email = "";
            this.details.twitter =  "";
            this.details.linkedIn = "";
          
      if (this.user) {
        if (this.user.company != null) {
          this.details.company_name = this.user.company.company_name;
          this.details.company_website = this.user.company.company_website;
          this.url = this.user.company.company_logo;
          this.details.zip_code = this.user.company.zip_code;
          this.details.address = this.user.company.address_1;
          this.details.city = this.user.company.city;
          this.details.Sector = this.user.company.sector;
          this.details.company_size = this.user.company.company_size;
          this.details.Industry = this.user.company.industry;
          this.details.address_two = this.user.company.address_2;
          this.details.state = this.user.company.state;
          this.details.legal_name = this.user.companyLegal.legal_contact_name;
          this.details.legal_title = this.user.companyLegal.legal_contact_title;
          this.details.legal_email = this.user.companyLegal.legal_contact_email;
          if (this.user.socialLink) {
            this.details.twitter = this.user.socialLink.twitter_profile_url
              ? this.user.socialLink.twitter_profile_url
              : "";
            this.details.linkedIn = this.user.socialLink.linkedin_profile_url
              ? this.user.socialLink.linkedin_profile_url
              : "";
          }
        }
      }
    },
    isLastNameEmpty() {
      // Check if the key exists in local storage
      // const userLastName = localStorage.getItem('user_last_name');
      // if (userLastName === '' || userLastName === null || userLastName === undefined) {
      //     this.companyProfile = false,
      //      this.updateCompanyProfile=  true
      // } else {
      //     this.companyProfile = true,
      //     this.updateCompanyProfile=  false
      // }
      if (!this.user.company) {
        this.companyProfile = false,
        this.updateCompanyProfile=  true; 
      } else {
        this.companyProfile = true,
        this.updateCompanyProfile=  false;
      }
    },
  },
  beforeMount(){
    this.getUserData();
  },
  mounted() {
    this.dataGet();
  },
};
</script>